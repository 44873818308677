@import '../../variables';

.SideBar {
  background-color: $admin-sidebar-bg;
  width: 15%;
  color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.Title > a > h2 {
  color: $admin-sidebar-tagline-color !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.MenuItem {
  width: 100%;
  padding-bottom: 4px;
  padding-top: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: white;
  text-decoration: none;
  padding-left: 6px;
}

.MenuItem:hover {
  background-color: $admin-sidebar-hover-item-bg;
  border-radius: 6px;
}

.activeLink > .MenuItem {
  background-color: $admin-sidebar-active-item-bg;
  border-radius: 6px;
  text-decoration: none;
}

.activeLink {
  text-decoration: none;
}

.Profile {
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  color: white;
}

.Name:hover {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  color: white;
  opacity: 0.9;
}

.Email {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.Logout {
  font-size: 18px;
  cursor: pointer;
}

.spacer {
  height: 6px;
}

.ContainerMenuItem{
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #FFF;
}
